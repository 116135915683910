
import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  name: 'legalInfoPanel',
  props: {
    title: {
      default: ''
    },
    haveChanges: {
      default: false
    },
    necessaryBefore: {
      default: ''
    },
    loadingChanges: {
      default: false
    },
    opened: {
      default: false
    },
    sectionHeight: {
      type: Number,
      required: true
    },
    itBuddy: {
      default: false
    }
  },
  data: () => ({
    openedPanel: false
  }),
  methods: {
    acceptChanges () {
      this.$emit('acceptChanges')
      this.openedPanel = false
    }
  },
  computed: {
    documentBlockLk () {
      return moment(this.necessaryBefore).isBefore(moment())
    },
    formattedDate () {
      const localTime = moment(this.necessaryBefore).locale('ru')
      return localTime.isValid() ? localTime.format('DD MMMM YYYY') : ''
    }
  },
  mounted () {
    if (this.opened) {
      this.openedPanel = 0
    } else {
      this.openedPanel = undefined
    }
  },
  watch: {
    opened (value: boolean) {
      if (value) {
        this.openedPanel = 0
      } else {
        this.openedPanel = undefined
      }
    },
    openedPanel (value: undefined | number) {
      if (value === 0) {
        this.$emit('onChange')
      }
    }
  }
})
