import { GetterTree, MutationTree, ActionTree } from 'vuex'
import services from '@/services/index'
import { UserDataInterface } from './types/index'

const namespaced = true

class State {
  isAuthenticated = false
  userCountryISO: null | string = null
  isLoaded = false
  userData = <UserDataInterface>{
    email: '',
    first_name: '',
    last_name: '',
    patronymic: '',
    phone: '',
    promo: '',
    pk: 0,
    uuid: '',
    discord_nickname: '',
    telegram_nickname: '',
    currentAccessRepairMethods: [],
    verificationRights: {
      currentLevelMonthLimit: null,
      currentRecovery: null,
      isNewUser: true,
      levelName: ''
    }
  }
}

const getters = <GetterTree<State, any>> {
  getIsAuthenticated: state => state.isAuthenticated,
  getUserCountryISO: (state: any) => state.userCountryISO,
  getUserData: state => state.userData,
  getIsLoaded: state => state.isLoaded
}

const actions = <ActionTree<State, any>> {
  async loadUser ({ commit }): Promise<void> {
    commit('setLoaded', false)
    // @ts-ignore
    const response = await services.authorization.user.loadUser(null)
    // @ts-ignore
    if (response.success) {
      // @ts-ignore
      commit('setUserData', response.data)
      commit('setIsAuthenticated', true)
    }
    commit('setLoaded', true)
  }

}

const mutations = <MutationTree<State>> {
  setIsAuthenticated (state, payload: boolean) { state.isAuthenticated = payload },
  setUserCountryISO (state, payload: string) { state.userCountryISO = payload },
  setUserData (state, payload: UserDataInterface) {
    state.userData = {
      ...state.userData,
      ...payload
    }
  },
  setCurrentAccessRepairMethods (state: any, payload: Array<string>) {
    state.userData.currentAccessRepairMethods = payload
  },
  setLoaded (state, payload: boolean) { state.isLoaded = payload }
}

export default {
  namespaced,
  state: new State(),
  actions,
  getters,
  mutations
}
