export enum AccessStatus {
  work = 'work',
  repeat = 'repeat'
}

export enum KycStatus {
  loading = 'loading',
  failed = 'failed',
  failedDouble = 'failed-double',
  conflict = 'conflict'
}

export interface IUnifiedAcc {
  id: string;
  fullName: string;
  email: string;
}
